<template>
<div>
  <b-btn variant="primary" class="mb-3" title="Download Resume" @click="generatePdf">
    Download
  </b-btn>
  <VueHtml2pdf
    :show-layout="true"
    :float-layout="false"
    :enable-download="true"
    :preview-modal="true"
    :paginate-elements-by-height="3000"
    :filename="'resume'"
    :pdf-quality="2"
    :manual-pagination="false"
    pdf-format="a4"
    pdf-orientation="portrait"
    pdf-content-width="100%"
    :pdf-content-height="1893"
    ref="html2Pdf"
  >
    <div class="resume-wrapper" slot="pdf-content" v-if="resumeDetails">
      <header class="resume-header">
        <h4>{{resumeDetails.user_name}}</h4>
        <div>
          <span>{{resumeDetails.user_address}}</span> |
          <span>(M) {{resumeDetails.user_mobile}}</span> |
          <span>{{resumeDetails.user_email}}</span> |
          <a :href="social_link" target="_blank" v-for="(social_link, index) of resumeDetails.user_social_links" :key="index">{{social_link}},&nbsp;</a>
        </div>
      </header>
      <!-- EDUCATION -->
      <section v-if="resumeDetails.user_education && resumeDetails.user_education" class="mb-2">
        <h5>EDUCATION</h5>
        <div class="horizontal-line"></div>
        <div v-for="(education, index) of resumeDetails.user_education" :key="index" class="mt-2">
          <h6>{{education.school_name}}&nbsp;//
            {{education.session_start_date}}-{{education.session_end_date}}
          </h6>
          <p>
            {{education.school_board}}-<b>({{education.agregate_percentage}}%)</b>
            <span v-if="education.current_activity" style="display: block;">{{education.current_activity}}</span>
          </p>

          <ol class="list">
            <li v-for="(achive, index) of education.achievements" :key="index">
              <p><b>{{achive.designation}}({{achive.tenure}})</b>:&nbsp;{{achive.details}}</p>
            </li>
          </ol>
        </div>
      </section><!-- EDUCATION -->

      <!-- EXPERIENCES -->
      <section class="mb-2" v-if="resumeDetails.user_experience.length > 0">
        <h5>EXPERIENCES</h5>
        <div class="horizontal-line"></div>
        <div v-if="resumeDetails.user_experience" class="mt-2">
          <div class="mt-2" v-for="(exp, index) of resumeDetails.user_experience" :key="index">
            <b>{{exp.role}}&nbsp;{{exp.org ? `//&nbsp;${exp.org}` : "" }}</b>
            <span class="list" v-if="exp.achievements">
              <li class="ml-4" v-for="(expAchieve, index) of exp.achievements" :key="index">
                <span>{{expAchieve}}</span>
              </li>
            </span>
          </div>
        </div>
      </section><!-- EXPERIENCES -->

      <!-- AWARDS AND HONOURS -->
      <section class="mb-2" v-if="resumeDetails.user_awards.length > 0">
        <h5>AWARDS AND HONOURS</h5>
        <div class="horizontal-line"></div>
        <div v-if="resumeDetails.user_awards">
          <div class="mt-2" v-for="(award, index) of resumeDetails.user_awards" :key="index">
            <b>{{award.title}}&nbsp;{{award.org ? `//&nbsp;${award.org}` : "" }}</b>
            <span class="list" v-if="award.details">
              <li class="ml-4" v-for="(detail, index) of award.details" :key="index">
                {{detail}}
              </li>
            </span>
          </div>
        </div>
      </section><!-- AWARDS AND HONOURS -->

      <!-- PROGRAMS & TEST -->
      <section class="mb-2" v-if="resumeDetails.user_program_test && resumeDetails.user_program_test.length > 0">
        <h5>PROGRAMS & TEST</h5>
        <div class="horizontal-line"></div>
        <div class="mt-2" v-if="resumeDetails.user_program_test">
          <div v-for="(test, index) of resumeDetails.user_program_test" :key="index">
            <b>{{test.examination_name}}-{{test.total_marks}}</b>
             (
              <span v-for="(subj, index) of test.marks_breakdown" :key="index">
                {{subj.subject}}-&nbsp;{{subj.marks}}
              </span>
              )
          </div>
        </div>
      </section><!-- PROGRAMS & TEST -->

      <!-- COURSES AND WORKSHOPS -->
      <section v-if="resumeDetails.user_courses.length > 0">
        <h5>COURSES AND WORKSHOPS</h5>
        <div class="horizontal-line"></div>
        <div v-if="resumeDetails.user_courses">
          <div class="mt-2" v-for="(course, index) of resumeDetails.user_courses" :key="index">
            <b>{{course.title}}&nbsp;{{course.org ? `//&nbsp;${course.org}` : "" }}</b>
            <span class="list" v-if="course.details">
              <li class="ml-4" v-for="(detail, index) of course.details" :key="index">
                {{detail}}
              </li>
            </span>
          </div>
        </div>
      </section><!-- COURSES AND WORKSHOPS -->
    </div>
  </VueHtml2pdf>
</div>
</template>

<script>
import { socialvue } from "../../../config/pluginInit"
import VueHtml2pdf from "vue-html2pdf"

export default {
  name: "Resume",
  components: {
    VueHtml2pdf
  },
  mounted () {
    socialvue.index()
  },
  data () {
    return {
      resumeDetails: {
        user_name: "Subho Yadav",
        user_address: "Kolkata, India",
        user_mobile: "9007259485",
        user_email: "subho@gide.ai",
        user_social_links: ["https://www.linkedin.com/in/subho-yadav-1a0981192/"],

        // Education Details
        user_education: [
          {
            school_name: "SVKM J.V. PAREKH International School",
            session_start_date: 2011,
            session_end_date: 2014,
            school_board: "Cambridge’s International General Certificate of Secondary Education",
            agregate_percentage: 94,
            current_activity: "Currently pursuing Advanced Subsidiary and Advanced Level"
          }
        ],

        // Program Test
        user_program_test: [
          {
            examination_name: "SAT(Scholarship Assessment Test)",
            total_marks: 1480,
            marks_breakdown: [
              {
                subject: "Reading and writing",
                marks: 730
              },
              {
                subject: "Maths",
                marks: 750
              }
            ]
          },
          {
            examination_name: "IELTS-Band",
            total_marks: 8,
            marks_breakdown: [
              {
                subject: "Speaking",
                marks: 8
              },
              {
                subject: "Listening",
                marks: 9
              },
              {
                subject: "Reading",
                marks: 8.5
              },
              {
                subject: "Writing",
                marks: 7
              }
            ]
          }
        ],

        // Experiences
        user_experience: [
          {
            exc_id: "1",
            role: "Head boy and Deputy Head boy; Student of the Year",
            achievements: [
              `Junior Deputy MUN Secretary; Core Team Member:Ploggathon- Plogging initiative by school; Awarded ‘Academic Excellence Award’ for meritorious performance and outstanding result in the CAIE AS Level Examinations; Contributing to school Instagram page`
            ]
          },
          {
            exc_id: "2",
            role: "Co-founder",
            org: "Project Gift A Smile",
            achievements: [
              `A student-led organisation with the objective to accelerate equal access to education and well-being amongst slum dwelling children. Managing 5 city heads across India.`,

              `Conducted a Christmas Book and Stationary donation drive, gathering 20,000 INR worth of supplies and 35 books.Organized a tote bag painting workshop for orphan girls. Impacted over 350+ lesser privileged children.`
            ]
          },
          {
            exc_id: "3",
            role: "Marketing Intern",
            org: "Wesleyan College, Georgia",
            achievements: [
              `Under the guidance of President Meaghan Blight, devised a multi-layer business strategy for international undergraduatestudent recruitment. Conducted primary and secondary market research and did SWOT analysis to develop an executivesummary, to create a regional expansion plan, illustrating trends and challenges to penetrate new markets.`,

              `Plan presented to the Senior leadership team. It is being implemented in the 2024 intake cycle.`
            ]
          },
          {
            role: "Publications",
            achievements: [
              `Authored and Illustrated book “When they became my Teachers”. Sold 150+ copies to raise 10,000 INR to donate to Angel Xpress Foundation, Mumbai, to fund a year of primary education for a child.`,

              `Co-authored anthologies: “Cluster of Ballads”, “Enlighten”, “Intoxication’, “Silenced Echoes”, “Elixir of Words”.`,

              `Compiled anthology “Mystic Melange”, providing opportunity to 30+ novice authors to publish their poetries.`
            ]
          },
          {
            role: "Winter Intern and Student Ambassador",
            org: "High School Moms, India",
            achievements: [
              `Interned under CEO Abhishek Gupta; adeptly tackled intricate case studies. Spearheaded research initiatives to identifyappropriate student career counselling methodologies and understand how to guide student better to choose a fulfilling career and the best-fit university.`,

              `Moderator at the Global Ed Connect, Asia's Largest Virtual Career and University Fair. Volunteered at 12 of their in-person events, promoting equal access to profile building for students from smaller cities.`
            ]
          },
          {
            role: "Summer Intern",
            org: "GIDE.AI, India",
            achievements: [
              `Built a comprehensive database for the ed-tech platform by researching 70 Polish universities, detailing their location, attendance costs, climate, and scholarship opportunities.`,

              `Initiated an Instagram series ‘Youth Unplugged’, to learn more about students’ life, aspirations, and challenges.`,

              `Student Lead of ‘Wealthy & Wise’ of the GIDE Student Club; Organized sessions on financial literacy for students.`
            ]
          }
          // {
          //   role: "Teacher",
          //   org: "Angel Xpress Foundation, India",
          //   achievements: [
          //     `Mentoring slum dwelling first-generation learners in English and Mathematics (40 hours)`
          //   ]
          // },
          // {
          //   role: "Head of Editorial Department",
          //   org: "Stand Up to Cancer India",
          //   achievements: [
          //     `Overseeing a team of 12 members including content writers and graphic designers.`,

          //     `Led content review processes, generated innovative ideas for social media campaigns and blogs, ensuring impactful and engaging communication strategies to foster awareness.`
          //   ]
          // }
        ],

        // Awards
        user_awards: [
          {
            exc_id: "4",
            title: "Best in Country Award",
            org: "Australian National University’s Business Case Competition",
            details: [
              `Over 2 months, teamed up with 3 peers to craft a comprehensive report, highlighting strong research and refinement of writing skills. Advanced to the final round as 1 of the 20 teams globally, presenting case study findings via a polished video, demonstrating effective communication, scriptwriting, and basic VFX in video editing.`
            ]
          },
          {
            exc_id: "5",
            title: "Debates and MUNs",
            details: [
              `Best Speaker and Team Runner-up at IIT x Bhubaneshwar National-level Debate; Representing school and country at Doxbridge (Durham, Oxford, and Cambridge University) 2023 selection International-level Debate.`,

              `Best Delegate at Diplomathon Global and IIMUN (Digital edition); Verbal Mention at IIMUN Independence edition.`
            ]
          },
          {
            exc_id: "6",
            title: "The Student of the year",
            org: "The Times of India",
            details: [
              `Awarded for outstanding academic performance and securing First Rank in the entire school.`
            ]
          },
          {
            exc_id: "7",
            title: "Bronze Award",
            org: "The Queens’s Commonwealth Essay Competition"
          }
          // {
          //   exc_id: "8",
          //   title: "Top 20 in India’s most exclusive Business and Entrepreneurship internship",
          //   org: "Huron University Canada, Flame University India, and Singapore Institute of Management",
          //   details: [
          //     `Attended sessions about financial literacy, social media marketing, and entrepreneurial strategies from business institutes.`,

          //     `Developed a novel product from scratch, ‘Dessert After Drink’, an anti-hangover drink, and presented business pitch to the jury. Created a cash flow schedule, advertising and promotional content and distributional plan as a part of the pitch. Selected in top 20 out of 2700 participants.`
          //   ]
          // },
          // {
          //   exc_id: "9",
          //   title: "#BeInspired ‘Inspirational Student of the Year’ Award",
          //   org: "High School Moms",
          //   details: [
          //     `Recognized for showcasing exemplary leadership, societal impact, and academic prowess; nominated by peers and teachers and chosen from a distinguished pool of over 2000 students by a panel of educational leaders.`
          //   ]
          // }
        ],

        // Courses
        user_courses: [
          {
            exc_id: "10",
            title: "Course ‘A Way with Words: Writing across Forms’, AshokaX Horizons",
            org: "Ashoka University, India",
            details: [
              `Delved into poetry, stories, and essays across English, French, and Japanese; enriched writing skills through workshops with Professor Sumana Roy, conducted peer reviews, and curated a portfolio of personal creative works.`
            ]
          }
        ]
      }
    }
  },
  props: {
    propResumeDetails: {
      type: Object,
      default: null
    }
  },
  methods: {
    /**
     * generatePdf
     */
    generatePdf () {
      this.hideBeforePrint = ""
      this.$refs.html2Pdf.generatePdf()
    }
  },
  watch: {
    propResumeDetails () {
      this.resumeDetails = this.propResumeDetails
    }
  }
}
</script>

<style lang="scss" scoped>
  .resume-wrapper {
    width: 100%;
    background-color: white;
    font-size: 10px;
    padding: 20px;
    font-family: 'Times New Roman', Times, serif !important;
    header {
      text-align: center;
    }
    section {
      width: 100%;
    }
  }

  .horizontal-line {
    border-bottom: 3px solid #e5252c;
  }
  .list {
    list-style-type: disc;
  }
</style>
